@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.inherit-w-full * {
  width: 100%;
}

.select-fit-content .ant-select-selector {
  height: fit-content !important;
}

.select-fit-content .ant-select-selection-item:after {
  display: none !important;
  content: "" !important;
}

.inherited-children > div {
  position: relative;
  max-width: inherit;
  box-sizing: content-box;
}

.m-0-children * {
  margin: 0 !important;
}

.no-asterisk .ant-form-item-required::before {
  display: none !important;
}
@media screen and (max-width: 768px) {
  body {
    zoom: 0.7;
  }
}
